import { Group, Stack } from '@mantine/core'
import { useRouter } from 'next/router'
import React from 'react'

import { MantineNextLink } from '~/components/shared/MantineNextLink'
import { ThatchButton } from '~/components/shared/ThatchButton'
import { ThatchPill } from '~/components/shared/ThatchPill'
import { Typography } from '~/components/shared/text/Typography'
import { ProfileSummaryType } from '~/endpoints/model'

import { SectionLayout } from './SectionLayout'
import { ThatchLink } from '~/components/shared/ThatchLink'

export const TagSection = ({ title, tags, linkToSearch = false }: { title: string; tags: string[], linkToSearch?: boolean }) => (
    <Stack spacing={24}>
        <Typography variant="eyebrow">{title}</Typography>

        <Group spacing={8}>
            {tags.map((tag, idx) =>
                linkToSearch ? (
                    <ThatchLink
                        key={idx}
                        to={`/search?query=${tag}`}
                        sx={{ textDecoration: 'none' }}
                        prefetch={false}
                    >
                        <ThatchPill
                            key={idx}
                            type="item"
                            label={tag}
                            bg="appPaper.0"
                            sx={{ border: 'none' }}
                        />
                    </ThatchLink>) : (
                    <ThatchPill
                        key={idx}
                        type="item"
                        label={tag}
                        bg="appPaper.0"
                        sx={{ border: 'none' }}
                        noPointer
                    />
                )
            )}
        </Group>
    </Stack >
)

interface TagsSectionProps {
    user: ProfileSummaryType
}

export const TagsSection: React.FC<TagsSectionProps> = ({ user }) => {
    const router = useRouter()

    return (
        <SectionLayout
            title={{ normal: 'Destinations, focuses & ', italic: 'specialties', gap: 14 }}
            style={{ maxWidth: 700 }}
            subtitle={[
                {
                    text: 'Your listing will appear in results for buyers who search for the destinations and tags you added in your seller profile. It’s important to keep these up to date.',
                },
            ]}
        >
            <MantineNextLink
                href={`/profile/@${user.username}/edit?section=tags`}
                target="_blank"
            >
                <ThatchButton
                    label="Edit your tags →"
                    sx={{ cursor: 'pointer', marginTop: -24, marginBottom: 24 }}
                    variant="filled"
                    size="small"
                />
            </MantineNextLink>
            <TagSection
                title="FOCUSES & SPECIALTIES"
                tags={user?.travelTypes ?? []}
            />
            <TagSection
                title="DESTINATIONS"
                tags={(user?.locations?.map(item => (item as any)?.name) as string[]) ?? []}
            />
        </SectionLayout>
    )
}
