import { Flex, Modal } from '@mantine/core'
import { Typography } from '~/components/shared/text/Typography'
import { ThatchButton } from '~/components/shared/ThatchButton'
import { ProfileSummaryType } from '~/endpoints/model'
import { getVideoUrl } from '~/components/shared/video'
import Player from '~/components/video/Player'
import { useScreenSize } from '~/hooks'
import { ThatchLink } from '~/components/shared/ThatchLink'

interface IntroVideoModalProps {
  opened: boolean
  setOpened: (value: boolean) => void
  videoUrl: string
  profile?: ProfileSummaryType
}
export const IntroVideoModal: React.FC<IntroVideoModalProps> = ({
  opened,
  setOpened,
  videoUrl,
  profile,
}) => {
  const { isMobileScreen } = useScreenSize()

  return (
    <Modal
      fullScreen={isMobileScreen}
      centered
      closeOnClickOutside={true}
      opened={opened}
      closeButtonProps={{
        iconSize: 20,
        style: {
          position: 'absolute',
          width: 40,
          height: 40,
          borderRadius: '50%',
          right: 18,
          top: 18,
          zIndex: 10,
          color: 'white',
          border: '1px solid black',
          backgroundColor: 'rgba(0,0,0,0.5)',
        },
      }}
      radius={isMobileScreen ? 0 : 16}
      onClose={() => setOpened(false)}
      styles={{ header: { marginBottom: 0, padding: 0, height: 0 }, body: { paddingRight: 0 } }}
      size="auto"
    >
      <Flex
        justify="center"
        style={{ height: 'min-content', overflow: 'hidden', position: 'relative' }}
      >
        <Player
          width={isMobileScreen ? '100%' : 'auto'}
          height={isMobileScreen ? '100vh' : '80vh'}
          url={getVideoUrl(videoUrl)}
          playing={true}
          initFullscreen={isMobileScreen}
        />
        {profile && <Flex justify="center" style={{ position: 'absolute', bottom: 55, left: 0, right: 0, width: '100%', zIndex: 10 }}>
          <ThatchLink to={`/@${profile.username}`}>
            <ThatchButton label={
              <Typography variant='button_small' className='px-6'>View Profile</Typography>
            } onClick={() => setOpened(false)} bg="appSuccess.0" className='text-white shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)]' size="medium" />
          </ThatchLink>
        </Flex>}
      </Flex>
    </Modal>
  )
}
