import { Button, Flex, Popover, Radio, rem } from '@mantine/core'
import { IconSettings, IconTrash, IconUpload } from '@tabler/icons'
import { useMutation } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { HireMeButton } from '~/components/seller/HireMeButton'
import { ProfileEvent, useProfileEventBuilder } from '~/analytics'
import { SubscriptionButton } from '~/components/shared/SubscriptionButton'
import { ThatchButton } from '~/components/shared/ThatchButton'
import { notify } from '~/components/shared/notify'
import { Typography } from '~/components/shared/text/Typography'
import { useAnalytics, useCurrentAuthState } from '~/context'
import { ProfileSummaryType, ThatchProStatus } from '~/endpoints/model'
import { deleteSellerDetails } from '~/endpoints/seller'
import { useAuth } from '~/hooks'
import { useMutateThatchProStatus } from '~/hooks/useProfile'
import { badgeOptions } from '~/utils/constants'

import { LoadingSpinner } from '../shared/loading/LoadingSpinnerDynamic'

export function ProfileActionButtons({ profile, setProfile, fullWidth=true }: { profile: ProfileSummaryType, setProfile?: (profile: ProfileSummaryType) => void, fullWidth?: boolean }) {
    const router = useRouter()
    const thatchSegment = useAnalytics()
    const profileEventPayload = useProfileEventBuilder(profile)
    const { id: authUserId, clientInitialized } = useAuth()
    const { userProfile } = useCurrentAuthState()

    const mut = useMutateThatchProStatus(profile.uid)

    const handleStatusChange = async (status: ThatchProStatus) => {
        await mut.mutateAsync(status, {
            onSuccess: prof => {
                if (setProfile) setProfile(prof)
                else router.reload()
            }
        })
        setOpened(false)
    }

    const {
        uid,
        username,
        proStatus,
        state: { following: isFollowing },
    } = profile

    const isOwner = authUserId === uid

    const isAdmin = userProfile?.admin ?? false

    const {
        mutateAsync: mutateDeleteSeller,
        isPending: isDeleteSellerLoading,
        isError: isDeleteSellerError,
    } = useMutation({ mutationFn: (uid: string) => deleteSellerDetails(uid) })

    const handleOnShareClick = async () => {
        const currentHost = window.location.host
        const shareUrl = `${window.location.protocol}//${currentHost}/@${username}`
        await navigator.clipboard.writeText(shareUrl)
        thatchSegment.trackProfileEvent(ProfileEvent.ShareClicked, profileEventPayload)
        notify(false, 'Link copied to clipboard', ' ')
    }

    const [opened, setOpened] = useState(false)

    const handleOpenSettings = () => {
        setOpened(o => !o)
    }

    const openModal = async () => {
        if (window.confirm('Are you sure you want to remove Stripe seller details?')) {
            await mutateDeleteSeller(uid, {
                onSuccess: () => {
                    notify(false, 'Seller details removed')
                },
                onError: error => {
                    notify(true, 'Failed to remove seller details', error.message)
                },
            })
        }
    }

    return (<>
        {!uid || !clientInitialized ? (
            <Flex
                my={32}
                align="center"
                justify="center"
            >
                <LoadingSpinner
                    customStyle={{ width: 30, height: 30 }}
                    color="black"
                />
            </Flex>
        ) : (
            <Flex
                my={32}
                gap={8}
                className='flex-wrap md:flex-nowrap'
            >
                {profile.hireable && <HireMeButton profileDetails={profile} />}

                {isOwner ? (
                    <ThatchButton
                        label="Edit Profile"
                        onClick={() => router.push(`/profile/@${username}/edit`)}
                        variant="outline"
                        size="medium"
                        sx={{
                            width: '100%',
                            border: '1px solid rgba(0,0,0,0.1)',
                            backgroundCor: 'white',
                        }}
                    />
                ) : (
                    <SubscriptionButton
                        sx={{
                            // width: fullWidth ? '100%' : 'auto',
                            flexGrow: fullWidth ? 1 : 0,
                            borderColor: 'rgba(0,0,0,0.1)',
                            ':hover': { backgroundColor: 'rgba(0,0,0,0.35)' },
                        }}
                        lightTheme
                        isFollowing={isFollowing}
                        userName={`@${username}`}
                        onSuccess={summary => {
                            if (setProfile) setProfile(summary)
                            else router.reload()
                        }}
                        analyticsCallback={(isFollow: boolean) => {
                            thatchSegment.trackProfileEvent(
                                isFollow ? ProfileEvent.UnSubscribeClicked : ProfileEvent.SubscribeClicked,
                                profileEventPayload
                            )
                        }}
                    />
                )}

                <Popover
                    shadow="lg"
                    withArrow
                    opened={opened}
                    onChange={() => setOpened(false)}
                >
                    <Popover.Target>
                        <Flex
                            onClick={isAdmin ? handleOpenSettings : handleOnShareClick}
                            miw={48}
                            mih={48}
                            align="center"
                            justify="center"
                            sx={{
                                borderRadius: '50%',
                                border: '1px solid rgba(0,0,0,0.1)',
                                cursor: 'pointer',
                                backgroundColor: 'white',
                                ':hover': { backgroundColor: 'rgba(0,0,0,0.35)' },
                            }}
                        >
                            {isAdmin ? <IconSettings /> : <IconUpload />}
                        </Flex>
                    </Popover.Target>

                    <Popover.Dropdown>
                        <Flex
                            direction="column"
                            gap={12}
                            my={8}
                        >
                            {badgeOptions.map((option, index) => (
                                <Radio
                                    key={`item-${index}`}
                                    value={option.value}
                                    styles={{
                                        label: { cursor: 'pointer' },
                                        radio: { cursor: 'pointer', borderWidth: 2.7, borderColor: 'black' },
                                    }}
                                    label={
                                        <Typography
                                            variant="body3"
                                            ml={8}
                                        >
                                            {option.label}
                                        </Typography>
                                    }
                                    color="appBlack.0"
                                    variant=""
                                    onChange={() => {
                                        handleStatusChange(option.value).catch(e =>
                                            notify(true, e.message, 'Could not change pro status')
                                        )
                                    }}
                                    checked={option.value === proStatus}
                                />
                            ))}
                        </Flex>
                        <Button
                            leftIcon={<IconTrash size={rem(18)} />}
                            variant="white"
                            color="red"
                            onClick={openModal}
                            loading={isDeleteSellerLoading}
                        >
                            Remove Seller
                        </Button>
                    </Popover.Dropdown>
                </Popover>
                {/* <div className='h-1 md:grow'/> */}
            </Flex>
        )}
    </>)
}